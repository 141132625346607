import { graphql, useStaticQuery } from "gatsby";

export default function GetDetails() {
    const { allContentfulDetails } = useStaticQuery(graphql`
        query{
            allContentfulDetails {
                edges {
                    node {
                       address
                       email
                       number
                    }
                }
            }
        }
    `)

    return allContentfulDetails.edges[0].node;
}